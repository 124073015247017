var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "photo" }, [
        _c("label", [_vm._v(_vm._s(_vm.label))]),
        _vm.displayedPhoto
          ? _c(
              "div",
              { staticClass: "photoContainer" },
              [
                _c("BaseImage", {
                  attrs: { source: _vm.displayedPhoto, size: "thumb" }
                }),
                _c("div", { staticClass: "photoOverlay text-center" }, [
                  _c(
                    "div",
                    [
                      _c("BaseIcon", {
                        staticClass: "icon iconHover iconEdit",
                        attrs: {
                          "icons-group": "far",
                          icon: "fa-edit",
                          size: "l"
                        },
                        on: {
                          click: function($event) {
                            _vm.isOpened = true
                          }
                        }
                      }),
                      _c("div", [_vm._v(_vm._s(_vm.$t("edit")))])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("BaseIcon", {
                        staticClass: "icon iconHover iconDelete",
                        attrs: {
                          "icons-group": "fas",
                          icon: "fa-ban",
                          size: "l"
                        },
                        on: { click: _vm.removePhoto }
                      }),
                      _c("div", [_vm._v(_vm._s(_vm.$t("remove")))])
                    ],
                    1
                  )
                ])
              ],
              1
            )
          : _c("div", { staticClass: "photoContainer" }, [
              _c("div", { staticClass: "photoOverlay" }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("BaseIcon", {
                      staticClass: "icon iconHover iconAdd",
                      attrs: {
                        "icons-group": "fas",
                        icon: "fa-plus-circle",
                        size: "xl"
                      },
                      on: {
                        click: function($event) {
                          _vm.isOpened = true
                        }
                      }
                    }),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("addItem", { item: _vm.$tc("photo") }))
                      )
                    ])
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "photoAlt" },
                [
                  _c("BaseIcon", {
                    staticClass: "icon iconImage",
                    attrs: {
                      "icons-group": "far",
                      icon: "fa-image",
                      size: "xxxl",
                      fill: ""
                    }
                  })
                ],
                1
              )
            ])
      ]),
      _c(
        "Modal",
        {
          attrs: { show: _vm.isOpened, centered: true, "show-close": true },
          on: {
            "update:show": function($event) {
              _vm.isOpened = $event
            }
          }
        },
        [
          _vm.isOpened
            ? _c("LibraryList", {
                staticClass: "library",
                attrs: {
                  limit: 40,
                  "select-items": "single",
                  "preselected-items": _vm.displayedPhoto
                    ? [_vm.displayedPhoto]
                    : [],
                  "fixed-type": "photos",
                  "grid-item-size": "100"
                },
                on: { updateSelected: _vm.updatePhoto }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "libraryButton text-right" },
            [
              _c(
                "BaseButton",
                {
                  attrs: { type: "green", gradient: "" },
                  on: { click: _vm.confirmNewPhoto }
                },
                [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }